/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvNameLabel}}</label
                  >
                  <input
                    v-model="vmGpathRoadmapFormData.name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationheader_description">
                    {{cvHeaderDescriptionLabel}}</label
                  >
                  <textarea
                    v-model="vmGpathRoadmapFormData.header_description"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationfooter_description">
                    {{cvFooterDescriptionLabel}}</label
                  >
                <textarea
                  v-model="vmGpathRoadmapFormData.footer_description"
                  type="text"
                  class="form-control textarea"
                  required ></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationhighlightdata">
                    {{cvHighlightdataLabel}}</label
                  >
                  <input
                    v-model="vmGpathRoadmapFormData.highlightdata"
                    type="text"
                    class="form-control"
                    required
                  />
                  </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationhighlightTitle">
                        {{cvHighlightTitleLabel}}</label
                      >
                      <input
                        v-model="vmGpathRoadmapFormData.highlightTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                  </div>
                    <div class="col-md-12 mb-3">
                      <label for="validationmark">
                        {{cvMarkLabel}}</label
                      >
                      <input
                        v-model="vmGpathRoadmapFormData.mark"
                        type="text"
                        class="form-control"
                        required
                      />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcourses_css">
                      {{cvCoursesCssLabel}}</label
                    >
                    <input
                      v-model="vmGpathRoadmapFormData.courses_css"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdetail">
                    {{cvDetailLabel}}</label
                  >
                  <textarea
                      v-model="vmGpathRoadmapFormData.detail"
                      type="text"
                      class="form-control textarea"
                      required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsubHeading">
                    {{cvSubHeadingLabel}}</label
                  >
                  <input
                    v-model="vmGpathRoadmapFormData.subHeading"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="gpathRoadmapAdd()"
                >{{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GpathRoadmaps } from "../../../FackApi/api/GpathRoadmap"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
export default {
  name: "GpathRoadmapAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "gpath_roadmap_add",
      cvCardTitle: "Add GPaths Roadmap",
      cvCardSubHeader: "Add GPaths Roadmap ",
      cvSubmitBtn: "Add",
      cvNameLabel: "name",
      cvHeaderDescriptionLabel: "header description",
      cvFooterDescriptionLabel: "footer description",
      cvHighlightdataLabel: "highlightdata",
      cvHighlightTitleLabel: "highlightTitle",
      cvMarkLabel: "mark",
      cvCoursesCssLabel: "courses css",
      cvDetailLabel: "detail",
      cvSubHeadingLabel: "subHeading",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GpathRoadmap Add Response",
      vmGpathRoadmapFormData: Object.assign({}, this.initFormData()),
      vmGpathRoadmapDesc: null,
      vmGpathRoadmapType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "name": "",
        "header_description": "",
        "footer_description": "",
        "highlightdata": "",
        "highlightTitle": "",
        "mark": "",
        "courses_css": "",
        "detail": "",
        "subHeading": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGpathRoadmapFormData) {
          if (!this.vmGpathRoadmapFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapAdd
     */
    async gpathRoadmapAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let gpathRoadmapAddResp = await GpathRoadmaps.gpathRoadmapAdd(this, this.vmGpathRoadmapFormData)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapAddResp)
        if (gpathRoadmapAddResp && !gpathRoadmapAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGpathRoadmapAddModal", gpathRoadmapAddResp.resp_data)
        }
        this.vmGpathRoadmapFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
